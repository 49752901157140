import React, { useEffect } from 'react';
import styled from 'styled-components';
import splashLogo from '../res/logo_splash.png';
import { logAmplitudeEvent } from '../amplitude';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px; // Вы можете настроить отступы по своему усмотрению
  box-sizing: border-box;
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const StartApp: React.FC<{ onTimeout: () => void }> = ({ onTimeout }) => {
  useEffect(() => {
    logAmplitudeEvent('e.0.0 - Initialization/Login to the site', { component: 'e.0.0 - Initialization/Login to the site' });

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isInAppBrowser = /Instagram|FB_IAB|FBAN/.test(navigator.userAgent); // Проверка на ин-апп браузер Instagram или Facebook

    if (isIOS && isInAppBrowser) {
      // Создаем ссылку
      const link = document.createElement('a');
      link.href = 'x-safari-https://golive.mobi';
      link.setAttribute('download', '');

      // Программно кликаем по ссылке
      document.body.appendChild(link);
      link.click();

      // Удаляем ссылку после клика
      document.body.removeChild(link);
    }

    const timer = setTimeout(() => {
      onTimeout();
    }, 1000);

    return () => clearTimeout(timer);
  }, [onTimeout]);

  return (
    <Container>
      <Logo src={splashLogo} alt="Splash Logo" />
    </Container>
  );
};

export default StartApp;